import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container,Form, FormGroup, Label, Input, FormText,InputGroup, InputGroupText, InputGroupAddon,} from 'reactstrap';
import axios from 'axios'


class AddJadwal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            tanggal: '',
            judul : '',
            imgFile: '',
            tempat: '',
            jadwal: '',
            waktu: '',
            sebagai: '',
            depkrisi: '',

         };
    }

    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }



    AddData = async () =>{
        const data = await new FormData();
    
      await data.append(
        "user_data",
        JSON.stringify({
            tanggal: this.state.tanggal,
            judul : this.state.judul,
            tempat: this.state.tempat,
            jadwal: this.state.jadwal,
            waktu: this.state.waktu,
            sebagai: this.state.sebagai,
            depkrisi: this.state.depkrisi,
            username : localStorage.getItem('username'),
        })
      );
    
      await data.append("imgFile", this.state.imgFile);

    
      const url = "https://apiform88.herokuapp.com/jadwal/createJadwal";
      axios.post(url , data, {
          headers: {
            'Content-Type': `multipart/form-data`,
          }
        })
        .then(res => {
            this.setState({
                tanggal: '',
                judul : '',
                imgFile: '',
                tempat: '',
                jadwal: '',
                waktu: '',
                sebagai: '',
                depkrisi: '',
            })
            this.props.refreshData()
            alert('data berhasil ditambahkan')
          
        
        })
        .catch(err => console.log(err));
    
      }



    render() {
        return (
            <Col xl="12" md="12" sm="12">
                 <div >
                    <ModalHeader>Tambah Jadwal</ModalHeader>
                    <ModalBody>
                    <Row xl="12" md="12" sm="12">
                        <Col xl="12" md="12" sm="12">
                        <FormGroup>
                            <Label for="examplePassword">Tanggal : </Label>
                            <Input type="date" name="tanggal" onChange={this.onChange} />
                        </FormGroup>
                        <FormGroup>
                        <Label for="exampleEmail">Photo</Label>
                        <Input type="file" name="imgFile" id="exampleEmail" placeholder="...."  onChange={ e => {
                                this.setState({
                                    imgFile :  e.target.files[0]
                                }) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Judul : </Label>
                            <Input value={this.state.judul} onChange={this.onChange} name="judul" placeholder="..."/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Jadwal : </Label>
                            <Input value={this.state.jadwal} onChange={this.onChange} name="jadwal" placeholder="..."/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Waktu : </Label>
                            <Input value={this.state.waktu} onChange={this.onChange} name="waktu" placeholder="..."/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Tempat : </Label>
                            <Input value={this.state.tempat} onChange={this.onChange} name="tempat" placeholder="..."/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Sebagai : </Label>
                            <Input value={this.state.sebagai} onChange={this.onChange} name="sebagai" placeholder="..."/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Depkrisi Acara: </Label>
                            <Input type="textarea"  value={this.state.depkrisi} onChange={this.onChange} name="depkrisi" placeholder="..."/>
                        </FormGroup>
                     
             
                       
                        </Col>
                    </Row>

                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.AddData} name="modalAdd">Tambah</Button>{' '}
                    <Button color="secondary" onClick={this.props.ActionModal} name="modalAdd">Cancel</Button>

                    </ModalFooter>
                    </div>
            
            </Col>
        );
    }
}

export default AddJadwal;