import React, {useState} from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table,Form, FormGroup, Label, Input, FormText} from "reactstrap";
import JoditEditor from "jodit-react";
import Axios from 'axios'

function AddData (props) {
	const [config, setConfig] = useState({
		readonly: false,
		toolbar: true,
		enter : "BR",
		autofocus : true,
		uploader : {
			insertImageAsBase64URI : true
		}
	})

	const [textAreaValue, setTextAreaValue] = useState('')

	const [DataArtikel, setDataArtikel] = useState('')

	const [Category, setCategory] = useState('')

	const [inputValue, setInputValue] = useState('')
	
	const [ dataInputObj, setDataInput ] = useState({ 
        kategori : '',
        imgMap: '',
        photoSampul : '',
		tanggal : '',
        namaPT:  '',
        HeadOffice: '',
        alamat : '',
        noHp : '',
        noTlp : '',
        email : '',
        website : '',
        siteOffice : '',
        location : '',
        miningLicense : '',
        issuanceDate : '',
        investmentScheme :  '',
        minerals: '',
        tenementStatus : '',
        initialArea : '',
		
	   })

	const [spin, setSpin] = useState(1)

	const toggleToolbar = () => (
		setConfig(config => ({
			...config,
			toolbar: !config.toolbar,
		}))
	)

	const toggleReadOnly = () => (
		setConfig(config => ({
			...config,
			readonly: !config.readonly,
		}))
  )

  const AddData = async () =>{

	const data = await new FormData();

  await data.append(
	"user_data",
	JSON.stringify({
		username : localStorage.getItem('username'),
		kategori : dataInputObj.kategori,
        text : textAreaValue,
		tanggal : dataInputObj.tanggal,
        namaPT:  dataInputObj.namaPT,
        HeadOffice: dataInputObj.HeadOffice,
        alamat : dataInputObj.alamat,
        noHp : dataInputObj.noHp,
        noTlp : dataInputObj.noTlp,
        email : dataInputObj.email,
        website : dataInputObj.website,
        siteOffice : dataInputObj.siteOffice,
        location : dataInputObj.location,
        miningLicense : dataInputObj.miningLicense,
        issuanceDate : dataInputObj.issuanceDate,
        investmentScheme :  dataInputObj.investmentScheme,
        minerals: dataInputObj.minerals,
        tenementStatus : dataInputObj.tenementStatus,
        initialArea : dataInputObj.initialArea,
	})
  );

  await data.append("photoSampul", dataInputObj.photoSampul);
  await data.append("imgMap", dataInputObj.imgMap)

  const url = "https://apiform88.herokuapp.com/companys/addMember";
  Axios.post(url , data, {
	  headers: {
		'Content-Type': `multipart/form-data`,
	  }
	})
	.then(res => {
	  dataInputObj.kategori = ''
	  dataInputObj.tanggal = ''
      dataInputObj.photoSampul = ''
      dataInputObj.imgMap = ""
      dataInputObj.text = ''
      dataInputObj.namaPT = ""
      dataInputObj.HeadOffice = ""
      dataInputObj.alamat = ""
      dataInputObj.noHp = ""
      dataInputObj.noTlp = ""
      dataInputObj.email = ""
      dataInputObj.website = ""
      dataInputObj.siteOffice = ""
      dataInputObj.location = ""
      dataInputObj.miningLicense = ""
      dataInputObj.issuanceDate = ""
      dataInputObj.investmentScheme = ""
      dataInputObj.minerals = ""
      dataInputObj.tenementStatus = ""
      dataInputObj.initialArea = ""

	  setDataInput(dataInputObj)
	  alert('Pendaftaran Berhasil')
	})
	.catch(err => console.log(err));

  }


  const getDataCategory = () =>{
	  Axios.get('https://apiform88.herokuapp.com/artikel/status')
	  .then(resData => {
		setCategory(()=> resData.data.data)
	  })
	  .catch(err => console.log(err))
  }
  


	const handleBlurAreaChange = () => {
		console.log('Blur')
	};

	const handleTextAreaChange = newTextAreaValue => {
		console.log('handleTextAreaChange', newTextAreaValue)
		return (
			setTextAreaValue(() => newTextAreaValue)
		)
	}

	const handleInputChange = e => {
		const {value} = e.target
		setInputValue(() => value)
		handleTextAreaChange(value)
	}

	const handleSpin = () => setSpin(spin => ++spin)
	const textHtml = DataArtikel
	return (  
		<Col xl="12" md="12" sm="12">
                 <Modal isOpen={true} size="lg"  >
                    <ModalHeader>Tambah Data Members</ModalHeader>
                    <ModalBody>
      
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        </Col>
      </Row>
      
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Nama PT</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.namaPT = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>

	  <Row xl="12" md="12" sm="12">
		  <Col xl="12" md="12" sm="12">
		  <FormGroup>
        <Label for="exampleDate">Tanggal</Label>
        <Input
          type="date"
		  name="tanggal"
		  onChange={ e => {
				dataInputObj.tanggal = e.target.value;
				setDataInput(dataInputObj)
		  }}
        />
      </FormGroup>
		  </Col>
	  </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
		<FormGroup>
        <Label for="exampleSelect">Kategori</Label>
        <Input type="select" name="select" id="exampleSelect" onClick={getDataCategory}   onChange={ e => {
				dataInputObj.kategori = e.target.value;
				setDataInput(dataInputObj)
		  }} >
          <option>Pilih Disini</option>
		  {Category == '' ? <option>Loading..</option> : Category.map(dataCategory => 
			<option value={dataCategory.id}  >{dataCategory.nama}</option>
			)}
        </Input>
      </FormGroup>
        </Col>
      </Row>
	  <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Photo Sampul</Label>
        <Input type="file" name="email" id="exampleEmail" placeholder="-"  onChange={ e => {
				dataInputObj.photoSampul =  e.target.files[0];
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Photo Lokasi</Label>
        <Input type="file" name="email" id="exampleEmail" placeholder="-"  onChange={ e => {
				dataInputObj.imgMap =  e.target.files[0];
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Head Office</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.HeadOffice = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">alamat</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.alamat = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">No Hp</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.noHp = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">No Tlp</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.noTlp = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Email</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.email = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Website</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.website = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Site Office</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.siteOffice = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Location</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="- " onChange={ e => {
				dataInputObj.location = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Mining License</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.miningLicense = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Issuance Date</Label>
        <Input
          type="date"
		  name="tanggal"
		  onChange={ e => {
				dataInputObj.issuanceDate = e.target.value;
				setDataInput(dataInputObj)
		  }}
        />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Investment Scheme</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.investmentScheme = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Minerals</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.minerals = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
     
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Tenement Status</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.tenementStatus = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Initial Area</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="-" onChange={ e => {
				dataInputObj.initialArea = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
      <Row xl="12" md="12" sm="12" style={{marginBottom:"1%"}} >
        <Col  xl="12" md="12" sm="12">
        <JoditEditor
				config={config}
				onChange={handleTextAreaChange}
				onBlur={handleBlurAreaChange}
				tabIndex={1}
				value={textAreaValue}/>
        </Col>
     
      </Row>
		
	  </ModalBody>
                    <ModalFooter>
                    <Button color="primary"  onClick={AddData} name="modalAdd">Tambah</Button>{' '}
                    <Button color="danger"  onClick={()=>{setTextAreaValue(() => '')}} >Clear</Button>
					<Button color="secondary"  onClick={props.ActionToggle} name="modalAdd">Cancel</Button>
                    </ModalFooter>
                    </Modal>
            
            </Col>

		// {/* <div className="Container" dangerouslySetInnerHTML={{__html: textHtml}}></div> */}
		
	)
}

export default AddData
