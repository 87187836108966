import logo from './logo.svg';
import './App.css';
import Page from './page.js'
import Aktivitas from './aktivitas.js'
import Jadwal from './addJadwal'
import Artikel from './artikelKhusus'
import Anggota from './addAnggota'
import { Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="Data">
      <Routes>
        <Route path="/hargaHPM" element={<Page />} />
        <Route path="/aktivitas" element={<Aktivitas />} />
        <Route path="/jadwalAktivitas" element={<Jadwal />} />
        <Route path="/artikelKhusus" element={<Artikel />} />
        <Route path="/anggota" element={<Anggota />} />
      </Routes>
      </div>
  );
}

export default App;
