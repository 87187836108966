import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container,Form, FormGroup, Label, Input, FormText,InputGroup, InputGroupText, InputGroupAddon,} from 'reactstrap';
import axios from 'axios'


class AddPrices extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            tanggal: '',
            priceHMA: '',
            priceInternational: '',
        

         };
    }

    onChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }



    AddData = async () =>{
        const data = await new FormData();
    
      await data.append(
        "user_data",
        JSON.stringify({
            tanggal: this.state.tanggal,
            username : localStorage.getItem('admin'),
        })
      );
    
      await data.append("priceHMA", this.state.priceHMA);
      await data.append("priceInternational", this.state.priceInternational);
    
      const url = "https://apiform88.herokuapp.com/prices/addPrices";
      axios.post(url , data, {
          headers: {
            'Content-Type': `multipart/form-data`,
          }
        })
        .then(res => {
            this.setState({
            priceInternational: '',
            priceHMA : '',
            tanggal: '',
            })
            this.props.refreshData()
            alert('data berhasil ditambahkan')
          
        
        })
        .catch(err => console.log(err));
    
      }



    render() {
        return (
            <Col xl="12" md="12" sm="12">
                 <Modal isOpen={true}  >
                    <ModalHeader>Tambah Harga</ModalHeader>
                    <ModalBody>
                    <Row xl="12" md="12" sm="12">
                        <Col xl="12" md="12" sm="12">
                        <FormGroup>
                            <Label for="examplePassword">Tanggal : </Label>
                            <Input type="date" name="tanggal" onChange={this.onChange} />
                        </FormGroup>
              
                        <FormGroup>
                        <Label for="exampleEmail">HMA</Label>
                        <Input type="file" name="priceHMA" id="exampleEmail"  onChange={ e => {
                                this.setState({
                                    priceHMA :  e.target.files[0]
                                }) }} />
                        </FormGroup>
                        <FormGroup>
                        <Label for="exampleEmail">Price International</Label>
                        <Input type="file" name="priceInternational" id="exampleEmail"   onChange={ e => {
                                this.setState({
                                    priceInternational :  e.target.files[0]
                                }) }} />
                        </FormGroup>
                       
                        </Col>
                    </Row>

                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.AddData} name="modalAdd">Tambah</Button>{' '}
                    <Button color="secondary" onClick={this.props.ActionModal} name="modalAdd">Cancel</Button>

                    </ModalFooter>
                    </Modal>
            
            </Col>
        );
    }
}

export default AddPrices;