import React, {useState} from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table,Form, FormGroup, Label, Input, FormText} from "reactstrap";
import JoditEditor from "jodit-react";
import Axios from 'axios'

function From (props) {
	const [config, setConfig] = useState({
		readonly: false,
		toolbar: true,
		enter : "BR",
		autofocus : true,
		uploader : {
			insertImageAsBase64URI : true
		}
	})

	const [textAreaValue, setTextAreaValue] = useState('')

	const [DataArtikel, setDataArtikel] = useState('')

	const [Category, setCategory] = useState('')

	const [inputValue, setInputValue] = useState('')
	
	const [ dataInputObj, setDataInput ] = useState({ 
		tanggal : '',
		kategori : '',
		photoSampul : '',
		depkrisiArtikel: '',
		judul: '',
		tags: ''
	   })

	const [spin, setSpin] = useState(1)

	const toggleToolbar = () => (
		setConfig(config => ({
			...config,
			toolbar: !config.toolbar,
		}))
	)

	const toggleReadOnly = () => (
		setConfig(config => ({
			...config,
			readonly: !config.readonly,
		}))
  )

  const AddData = async () =>{

	const data = await new FormData();

  await data.append(
	"user_data",
	JSON.stringify({
		judul: dataInputObj.judul,
		depkrisiArtikel : dataInputObj.depkrisiArtikel,
		tanggal: dataInputObj.tanggal,
		username : localStorage.getItem('username'),
		kategori : dataInputObj.kategori,
		text : textAreaValue,
		tags: dataInputObj.tags
	})
  )

  await data.append("photoSampul", dataInputObj.photoSampul)

  const url = "https://apiform88.herokuapp.com/artikel/createArtikel";
  Axios.post( url , data, {
	  headers: {
		'Content-Type': `multipart/form-data`,
	  }
	})
	.then(res => {
	  dataInputObj.judul = ''
	  dataInputObj.depkrisiArtikel = ''
	  dataInputObj.kategori = ''
	  dataInputObj.tanggal = ''
	  dataInputObj.photoSampul = ''
	  dataInputObj.text = ''
	  dataInputObj.tags = ''

	  setDataInput(dataInputObj)
	  props.refreshData()
	  alert('Pendaftaran Berhasil')
	})
	.catch(err => console.log(err));

  }


  const getDataCategory = () =>{
	  Axios.get('https://apiform88.herokuapp.com/artikel/khusus')
	  .then(resData => {
		setCategory(()=> resData.data.data)
	  })
	  .catch(err => console.log(err))
  }
  


	const handleBlurAreaChange = () => {
		console.log('Blur')
	};

	const handleTextAreaChange = newTextAreaValue => {
		console.log('handleTextAreaChange', newTextAreaValue)
		return (
			setTextAreaValue(() => newTextAreaValue)
		)
	}

	const handleInputChange = e => {
		const {value} = e.target
		setInputValue(() => value)
		handleTextAreaChange(value)
	}

	const handleSpin = () => setSpin(spin => ++spin)
	const textHtml = DataArtikel
	return (  
		<Col xl="12" md="12" sm="12">
                 <Modal isOpen={true} size="lg"  >
                    <ModalHeader>Tambah Artikel</ModalHeader>
                    <ModalBody>
      
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        </Col>
      </Row>
      
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Judul Artikel</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="Tulis Judul Artkel Disini" onChange={ e => {
				dataInputObj.judul = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
	  <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Tags</Label>
        <Input type="text" name="email" id="exampleEmail" placeholder="isi tags disini" onChange={ e => {
				dataInputObj.tags = e.target.value;
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
	  <Row xl="12" md="12" sm="12">
		  <Col xl="12" md="12" sm="12">
		  <FormGroup>
        <Label for="exampleDate">Tanggal</Label>
        <Input
          type="date"
		  name="tanggal"
		  onChange={ e => {
				dataInputObj.tanggal = e.target.value;
				setDataInput(dataInputObj)
		  }}
        />
      </FormGroup>
		  </Col>
	  </Row>
      <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
		<FormGroup>
        <Label for="exampleSelect">Kategori</Label>
        <Input type="select" name="select" id="exampleSelect" onClick={getDataCategory}   onChange={ e => {
				dataInputObj.kategori = e.target.value;
				setDataInput(dataInputObj)
		  }} >
          <option>Pilih Disini</option>
		<option value="21"  >Kabar Nikel</option>
		<option value="31"  >Opini</option>
		<option value="41"  >Aktivitas</option>
		<option value="51"  >Pertambangan</option>
		<option value="61"  >Hukum</option>
        </Input>
      </FormGroup>
        </Col>
      </Row>
	  <Row xl="12" md="12" sm="12" >
        <Col xl="12" md="12" sm="12">
        <FormGroup>
        <Label for="exampleEmail">Photo Sampul</Label>
        <Input type="file" name="email" id="exampleEmail" placeholder="Tulis Judul Artkel Disini"  onChange={ e => {
				dataInputObj.photoSampul =  e.target.files[0];
				setDataInput(dataInputObj)
		  }} />
      </FormGroup>
        </Col>
      </Row>
	  <Row xl='12' md='12' sm='12'>

			  <Col xl="12" md="12" sm="12">
		  			  <FormGroup>
							<Label for="exampleText">Depkrisi Artikel</Label>
							<Input type="text" name="email" id="exampleEmail" placeholder="depkrisis singkat" onChange={ e => {
									dataInputObj.depkrisiArtikel = e.target.value;
									setDataInput(dataInputObj)
		  					 }} />

					  </FormGroup>
			  </Col>

	  </Row>
      <Row xl="12" md="12" sm="12" style={{marginBottom:"1%"}} >
        <Col  xl="12" md="12" sm="12">
        <JoditEditor
				config={config}
				onChange={handleTextAreaChange}
				onBlur={handleBlurAreaChange}
				tabIndex={1}
				value={textAreaValue}/>
        </Col>
     
      </Row>
		
			{/* <button
				onClick={toggleReadOnly}
				type={"button"}>
				{'Toggle Read-Only'}
			</button>
			<button
				onClick={toggleToolbar}
				type={"button"}>
				{'Toggle Toolbar'}
			</button>
			<button
				type={"button"}
				onClick={handleSpin}>
				{`Spin ${spin}`}
			</button> */}
	  </ModalBody>
                    <ModalFooter>
                    <Button color="primary"  onClick={AddData} name="modalAdd">Tambah</Button>{' '}
                    <Button color="danger"  onClick={()=>{setTextAreaValue(() => '')}} >Clear</Button>
					<Button color="secondary"  onClick={props.ActionToggle} name="modalAdd">Cancel</Button>
                    </ModalFooter>
                    </Modal>
            
            </Col>

		// {/* <div className="Container" dangerouslySetInnerHTML={{__html: textHtml}}></div> */}
		
	)
}

export default From